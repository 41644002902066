<template>
<b-sidebar :lazy="true" id="sidebar-1" title="Editor" bg-variant="dark" text-variant="light" shadow backdrop right no-header :visible="style_id && style_id.length > 0" @shown="classList('body', 'add', 'noscroll')"
	@hidden="removeStyleId(); classList('body', 'remove', 'noscroll')">
	<div class="card h-100">
		<!-- <Ad placement="flexbar" data-no-prerender /> -->
		<div class="card-header">
			<div class="row pt-5">
				<div class="col align-self-center ellipsis">
					<div class="url-display m-0">
						<a :href="editableStyle.key" :title="editableStyle.key" target="_blank" class="d-block ellipsis printed-url" v-if="editableStyle && editableStyle.key.match('http')">{{ getUrlParts(editableStyle.key).hostname }}<span
								class="text-muted">{{ getUrlParts(editableStyle.key).pathname }}{{ getUrlParts(editableStyle.key).search }}{{ getUrlParts(editableStyle.key).hash }}</span></a>
						<a :href="'http://' + editableStyle.key" :title="editableStyle.key" target="_blank" class="d-block ellipsis printed-url" v-else>{{ editableStyle.key }}</a>
					</div>
				</div>
				<div class="col-auto align-self-center pl-0">
					<div class="arrow-button d-flex align-items-center justify-content-center" v-b-toggle.sidebar-1>
						<span class="material-icons-outlined md-light">close</span>
					</div>
				</div>
			</div>
		</div>
		<div class="card-body flex-grow-1 flex-column position-relative">
			<b-row class="h-100">
				<b-col>
					<CodeEditor v-if="options && editableStyle" ref="editor" editorType="app" :tabUrlKey="editableStyle.key" :options="options" @modified="updateCodeState" @onsave="onSave" @onerror="saveError" @onsuccess="saveSuccess"
						@onloaded="onLoad" @typing="onTyping" @pending="onPending"></CodeEditor>
					<nav class="editor-toolbar text-center">
						<div class="copy-editor" v-b-tooltip.hover.left="'Copy to Clipboard'" title="Copy to Clipboard" @click="$refs.editor.copyCode(); makeToast('Copied to clipboard');">
							<b-button class="header-btn d-flex align-items-center justify-content-center" size="sm" variant="outline-dark" pill>
								<span class="material-icons-outlined">
									content_copy
								</span>
							</b-button>
						</div>
						<div class="format-css" v-b-tooltip.hover.left="'Format CSS'" title="Format CSS" @click.prevent="beautify">
							<b-button class="header-btn d-flex align-items-center justify-content-center" size="sm" variant="outline-dark" pill>
								<span class="material-icons-outlined">
									format_align_left
								</span>
							</b-button>
						</div>
					</nav>
					<div style="position: absolute; bottom: 0; right: 1.5rem;">
						<Ad class="fab-wrapper" type="carbon" v-slot="{ ad }" :show="showAd">
							<b-button class="btn-dismiss" variant="link" @click.prevent="showAd=false">
								<span class="material-icons-outlined">cancel</span>
							</b-button>
							<a class="ad rounded d-block" :href="`${apiURL}/cdn/r/${base64Encode(ad.statlink)}`" target="_self" v-if="['custom', 'default'].includes(ad.rendering)">
								<div class="d-flex">
									<div>
										<div class="d-inline-block logo-wrapper rounded p-3" :style="{ 'background-color': ad.backgroundColor}">
											<img :src="`${apiURL}/cdn/img/${base64Encode(ad.logo)}`" title="Sponsored By Carbon" class="d-block" style="max-width: 125px;" />
										</div>
									</div>
									<div class="w-100 d-flex flex-column">
										<div class="description">
											{{ ad.description }}
										</div>
										<div class="meta text-muted">
											Ads by Carbon
										</div>
									</div>
								</div>
							</a>
							<a class="ad smaller rounded d-block" :href="`${apiURL}/cdn/r/${base64Encode(ad.statlink)}`" target="_self" v-if="ad.rendering == 'carbon'">
								<div class="logo-wrapper">
									<img :src="`${apiURL}/cdn/img/${base64Encode(ad.smallImage)}`" title="Ads By Carbon" class="d-block rounded" style="max-width: 130px;" />
								</div>
								<div>
									<div class="description">
										{{ ad.description }}
									</div>
									<div class="meta text-muted">
										Ads by Carbon
									</div>
								</div>
							</a>
						</Ad>
					</div>
				</b-col>
			</b-row>
		</div>
		<footer class="card-footer">
			<div class="row">
				<div class="col-auto align-self-center">
					<StatefulButton class="btn btn-primary btn-sm save-btn" :state="saveButtonState" @click.prevent="saveCode">
						<template #typing>
							<b-spinner small label="Small Spinner"></b-spinner>
						</template>
						<template #pending>
							Save
						</template>
						<template #active>
							Save
						</template>
						<template #disabled>
							Save
						</template>
					</StatefulButton>
				</div>
				<div class="col pl-0 align-self-center autosave small" style="font-size: 12px;" :class="{ active: unsavedChanges }">
					Showing unsaved changes.
					<a href="#" class="text-underline" @click.prevent="discardChanges">
						Discard changes.
					</a>
				</div>
			</div>
		</footer>
	</div>
</b-sidebar>
</template>

<script>
const Ad = () => import('@/components/Ad');
const CodeEditor = () => import('@/components/amino-vue-components/CodeEditor');
const StatefulButton = () => import('@/components/amino-vue-components/StatefulButton');
export default {
	name: 'Library',
	components: {
		Ad,
		CodeEditor,
		StatefulButton
	},
	props: [
		'options'
	],
	data() {
		return {
			editableStyle: false,
			saveButtonState: 'disabled',
			unsavedChanges: false,
			pendingChanges: false,
			bust: (new Date()).getTime(),
			showAd: true
		};
	},
	computed: {
		style_id() {
			return this.$route.params.style_id;
		},
		Styles() {
			const {
				Styles
			} = this.$FeathersVuex.api;
			return Styles;
		}
	},
	methods: {
		removeStyleId() {
			this.$router.replace({
				query: this.$route.query,
				params: {
					style_id: undefined
				}
			});
		},
		async getEditableStyle(id) {
			this.editableStyle = await this.Styles.get(id);
		},
		beautify() {
			this.$refs.editor.beautify();
		},
		copyCode() {
			this.$refs.editor.copyCode().then(() => {
				this.makeToast('Copied to clipboard.');
			});
		},
		saveCode() {
			this.saveButtonState = 'pending';
			this.$refs.editor.save();
		},
		onSave() {
			this.saveButtonState = 'pending';
		},
		saveSuccess(style) {
			this.style = style;
			this.saveButtonState = 'disabled';
			this.unsavedChanges = false;
		},
		saveError(err) {
			this.saveButtonState = 'active';
			if (err.data) {
				if (err.data.reason === 'QUOTA_EXCEEDED') {
					this.$bvModal.msgBoxOk(err.message, {
							// title: 'Whoops...',
							size: 'sm',
							buttonSize: 'sm',
							okVariant: 'primary',
							okTitle: 'Upgrade for Unlimited Storage',
							// headerTextVariant: 'danger',
							// bodyTextVariant: 'danger',
							headerClass: 'p-2 border-bottom-0',
							footerClass: 'p-2 border-top-0',
							centered: true
						})
						.then(value => {
							this.boxTwo = value
						});
				}
			}
		},
		onLoad(style) {
			this.style = style;
		},
		onTyping() {
			this.saveButtonState = 'typing';
		},
		onPending() {
			this.pendingChanges = true;
		},
		discardChanges() {
			this.$refs.editor.discardChanges();
			this.unsavedChanges = false;
			this.saveButtonState = 'disabled';
			this.$refs.editor.removeDraft();
		},
		updateCodeState(unsavedChanges) {
			this.unsavedChanges = unsavedChanges;
			if (this.unsavedChanges) {
				this.saveButtonState = 'active';
			} else {
				this.saveButtonState = 'disabled';
			}
		},
		async protectChanges() {
			if (this.$refs.editor) {
				await this.$refs.editor.saveDraft();
			}
			return;
		}
	},
	watch: {
		'$route.params.style_id': async function(newVal) {
			if (newVal) {
				this.bust = (new Date()).getTime();
				this.getEditableStyle(newVal);
			} else {
				await this.protectChanges();
				this.editableStyle = false;
			}
		}
	},
	async mounted() {
		window.addEventListener('beforeunload', this.protectChanges);
		if (this.$route.params.style_id) {
			this.getEditableStyle(this.$route.params.style_id);
		}
	},
	unmounted() {
		window.removeEventListener('beforeunload', this.protectChanges);
	},
	beforeRouteLeave() {
		window.removeEventListener('beforeunload', this.protectChanges);
		this.protectChanges();
	}
}
</script>

<style scoped lang="scss">
#sidebar-1 {

	.card {
		width: var(--popout-width);
		overflow: auto;
		border-radius: 0;
		background-color: var(--color-700);
		border: 0;
	}

	.card-header {
		background-color: var(--color-900);
		padding: 1.5rem;
		z-index: 99;
		border: 0;

		.url-display {
			font-size: 1.35rem;
			font-weight: var(--font-weight-bold);
			user-select: none;
			color: var(--color-white) !important;
		}
	}

	.card-body {
		padding: 1.5rem;
	}

	.card-footer {
		padding: 1.5rem;
		background: none;
		z-index: 999;
		box-shadow: 0;
		border-top: 1px solid var(--color-500);

		.save-btn {
			padding: 0.5em 0.85em;
			display: flex;
			align-items: center;

			&.pending-state {
				background-color: var(--color-primary);
				color: white;
			}

			.spinner {
				width: 8px;
				height: 8px;
				border-radius: 50%;
				margin-left: 5px;
				background: transparent;
				border-top: 1px solid white;
				border-right: 1px solid white;
				border-bottom: 1px solid transparent;
				border-left: 1px solid transparent;
				animation: loading 0.5s infinite linear;
			}
		}

		.autosave {
			color: var(--color-100);
			transition: var(--property-transition);
			opacity: 0;
			visibility: hidden;

			&.active {
				opacity: 1;
				visibility: visible;
			}

			a {
				color: var(--color-400);
				text-decoration: underline;

				&:hover {
					color: inherit;
				}
			}
		}

		.review-cta {
			color: var(--color-500);

			&:hover {
				color: var(--color-body);
			}

			i {
				margin-top: -2px;
				vertical-align: middle;
			}
		}
	}

	.editor-toolbar {
		position: absolute;
		top: 1.5rem;
		right: 1.5rem;
		color: var(--color-100);
		.material-icons-outlined {
			font-size: 1.125rem;
		}
		div {
			cursor: pointer;
			margin-bottom: 0.5rem;
			&:hover {
				color: var(--color-body);
			}
		}
	}

	.fab-wrapper {
		.btn-dismiss {
			position: absolute;
			top: 0;
			right: 0;
			padding: 0.5rem;
			transform: translate(0.5rem, -100%);
			height: auto;
			line-height: 0;
			.material-icons-outlined {
				font-size: 1rem !important;
			}
		}
	}
	.ad {
		max-width: 400px;
		margin: 0.5rem auto 0;
		padding: 1.5rem;
		position: relative;
		background-color: var(--color-500);
		transition: var(--property-transition);
		&.smaller {
			max-width: 240px;
			.logo-wrapper {
				margin-bottom: 1rem;
				img {
					max-width: none;
				}
			}
		}
		&:hover {
			transform: scale(1.025);
		}
		.logo-wrapper {
			margin-right: 1.5rem;
			img {
				min-width: 3rem;
			}
		}
		.description {
			font-size: 0.85rem;
			line-height: 1.3;
		}
		.meta {
			font-size: 0.75rem;
			line-height: 1.4;
			margin-top: 0.5rem;
		}
		a {
			color: inherit;
			text-decoration: none;
			color: var(--color-text);
		}
		@media only screen and (max-width: 900px) {
			max-width: 300px;
		}
	}
}
</style>
